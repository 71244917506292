import React, { useContext, useEffect, useState } from "react"
import { Container, Row, Col, Form } from 'react-bootstrap';
import UserNav from "./UserNav";
import UserContext from "../../../contexts/UserContext";
import { BookingServiceApis } from "../../../services/apiService";

function MyPackages(props) {

    const { user } = useContext(UserContext)

    const [packages, setPackages] = useState([])
    const getAllUserServices = () => {
        let body = {
            bookingType: "PACKAGE"
        }
        let headers = {
            "x-access-token": user?.token
        }
        BookingServiceApis.getUserBookings(body, headers)
            .then(res => {
                console.log(res.data)
                if (res.data.status === true) {
                    setPackages(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getAllUserServices()
    }, [user])

    return (

        <div className="MyAccount-container">
            <div className='inner-hero'>
                <div className='inner-hero-image'>
                    <img src='./assets/img/inner-banner.png' />
                </div>
                <div className='herotext wow fadeInUp'>
                    <Container>
                        <h2>Recovery Lab Qatar</h2>
                        <h1>My Account</h1>
                    </Container>
                </div>
            </div>

            <section className="myaccount-section">
                <Container>
                    <Form className="formStyles">
                        <Row>
                            <Col lg={3}>
                                <UserNav />
                            </Col>
                            <Col lg={9} className="mt-4 mt-lg-0">
                                <div className="account-heading d-flex mb-3 pb-2">
                                    <h4>My Packages</h4>
                                </div>

                                <Row>
                                    {packages.map((item, index) => {
                                        return (
                                            <Col md={4} key={index}>
                                                <div className='card package-box'>
                                                    <div className='card-body'>
                                                        <h5 title={JSON.parse(item?.bookingService)?.packageName}>{JSON.parse(item?.bookingService)?.packageName}</h5>
                                                        <div className='package-des'>
                                                            <p dangerouslySetInnerHTML={{ __html: JSON.parse(item?.bookingService)?.longDescription }} />
                                                        </div>
                                                        <div className='card-price mb-0'><span className='p-simbol'>QAR. </span>{item.totalAmount}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )

                                    }
                                    )}
                                </Row>

                            </Col>
                        </Row>
                    </Form>
                </Container>
            </section>
        </div>
    )

}

export default MyPackages