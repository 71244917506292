import React, { useContext, useEffect, useState } from "react"
import { Container, Row, Col, Form } from 'react-bootstrap';
import { GoArrowRight } from "react-icons/go";
import UserNav from "./UserNav";
import UserContext from "../../../contexts/UserContext";
import { BookingServiceApis } from "../../../services/apiService";

function MyMembership() {

    const { user } = useContext(UserContext)

    const [memberships, setMemberships] = useState([])
    const getAllUserServices = () => {
        let body = {
            bookingType: "MEMBERSHIP"
        }
        let headers = {
            "x-access-token": user?.token
        }
        BookingServiceApis.getUserBookings(body, headers)
            .then(res => {
                console.log(res.data)
                if (res.data.status === true) {
                    setMemberships(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getAllUserServices()
    }, [user])

    return (

        <div className="MyAccount-container">
            <div className='inner-hero'>
                <div className='inner-hero-image'>
                    <img src='./assets/img/inner-banner.png'/>
                </div>
                <div className='herotext wow fadeInUp'>
                    <Container>
                        <h2>Recovery Lab Qatar</h2>
                        <h1>My Account</h1>
                    </Container>
                </div>
            </div>

            <section className="myaccount-section">
                <Container>
                    <Form className="formStyles">
                        <Row>
                            <Col lg={3}>
                                <UserNav/>
                            </Col>
                            <Col lg={9} className="mt-4 mt-lg-0">
                                <div className="account-heading d-flex mb-3 pb-2">
                                    <h4>My Memberships</h4>
                                </div>

                                <Row className="flash-white-card">
                                    {
                                        memberships && memberships.map(membership=> {
                                            return (
                                                <Col md={4}>
                                                <div className='card package-box'>
                                                    <div className='card-body'>
                                                        <h5>{JSON.parse(membership?.bookingService).packageName}</h5>
                                                        <div className='package-des'>
                                                        <p dangerouslySetInnerHTML={{__html: JSON.parse(membership?.bookingService).longDescription}} ></p>
                                                        </div>
                                                        <div className='card-price mb-0'><span className='p-simbol'>QAR. </span>{membership?.totalAmount}<span className="session">/ Monthly</span></div>
                                                        
                                                    </div>
                                                </div>
                                            </Col>
                                            )
                                        })
                                    }
                                   
                                </Row>

                            </Col>
                        </Row>
                    </Form>
                </Container>
            </section>
        </div>
    )

}

export default MyMembership